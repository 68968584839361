<template>
  <b-overlay
    :variant="skin"
    :show="loading && is_accounts"
    :no-wrap="loading && is_accounts"
    spinner-variant="warning"
  >
    <template v-slot:overlay>
      <p class="mb-2">Carregando suas configurações...</p>
      <b-overlay :show="loading && is_accounts" spinner-variant="primary" spinner-type="grow" opacity="0"></b-overlay>
    </template>

    <div class="auth-wrapper auth-v2">
      <div id="bodyCaller"></div>
      <b-row class="auth-inner m-0">
        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="
              w-100
              d-lg-flex
              align-items-center
              justify-content-center
              px-5
            "
          >
            <b-img fluid :src="imgUrl" alt="Login" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <div
              class="mb-5"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              "
            >
              <img :src="logoUrl" />
            </div>

            <!-- form -->
            <validation-observer  ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="execLogin">
                <!-- email -->
                <b-form-group label="Login" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Login"
                    vid="email"
                    rules="required"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Senha</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>Esqueceu a Senha?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  <b-spinner type="grow" v-if="loading" />
                  {{ loading ? "" : "ACESSAR" }}
                </b-button>
                <!-- divider -->
                <div class="divider my-2">
                  <div class="divider-text">ou</div>
                </div>
                <b-button variant="info" @click="loginWithGoogle" block>
                  <img class="ml-50" v-if="!loading" :src="googleIconUrl" />
                  <b-spinner type="grow" v-if="loading" />
                  {{ loading ? "" : "ENTRAR COM O GOOGLE" }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import _authService from "@/services/auth-service";
import rotas from "@/navigation/vertical/index";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logo: require("@/assets/images/logo/logo.svg"),
      is_accounts: false,
      loading: false,
      required,
      email,
      google_token: null,
      showLoginForm: false,
      googleIcon: require("@/assets/images/svg/google.svg"),
    };
  },
  created() {
    if (this.$route.query.token) {
      this.is_accounts = true;
    }
    localStorage.clear();
  },
  mounted() {
    localize("pt_BR", pt_br);
    if (this.$route.query.token) {
      this.login();
      this.setConfigAccounts();
    }
    if (this.$route.query.devmode) {
      this.showLoginForm = true;
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.logo = require("@/assets/images/logo/logo_dark.svg");
        return this.logo;
      }
      return this.logo;
    },
    googleIconUrl() {
      return this.googleIcon;
    },
  },
  methods: {
    loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          // on success do something
          /*  console.log('GoogleUser', GoogleUser)
          console.log('getId', GoogleUser.getId())
          console.log('basicprofile', GoogleUser.getBasicProfile().getName())
          console.log('getBasicProfile', GoogleUser.getBasicProfile())
          console.log('getAuthResponse', GoogleUser.getAuthResponse())
          var userInfo = {
            loginType: 'google',
            google: {
              auth: GoogleUser.getAuthResponse(),
              user: {
                name: GoogleUser.getBasicProfile().getName(),
                email: GoogleUser.getBasicProfile().getEmail(),
                profileImage: GoogleUser.getBasicProfile().getImageUrl()
              }
            }
          }
          console.log(userInfo);*/
          this.google_token = GoogleUser.getAuthResponse().id_token;
          this.login();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getrotas() {
      let rotasR = [];
      if (rotas) {
        rotas.forEach((_row) => {
          if (_row.roles) {
            rotasR.push({ route: _row.route, name: _row.roles.join(", ") });
          }

          if (_row.children) {
            _row.children.forEach((_cchild) => {
              if (_cchild.roles) {
                rotasR.push({
                  route: _cchild.route,
                  name: _cchild.roles.join(", "),
                });
              }
            });
          }

          if (_row.main) {
            _row.main.forEach((_main) => {
              if (_main.roles) {
                rotasR.push({
                  route: _main.route,
                  name: _main.roles.join(", "),
                });
              }

              if (_main.child) {
                _main.child.forEach((_cchild) => {
                  rotasR.push({
                    route: _cchild.route,
                    name: _cchild.roles.join(", "),
                  });
                });
              }
            });
          }
        });
      }
      return rotasR;
    },
    getMenus(userData) {
      var _rrotas = JSON.parse(JSON.stringify(rotas));
      _rrotas.forEach((menu) => {
        if (menu.title === "Relatórios") {
          if (userData.reports && userData.reports.length > 0) {
            userData.reports.forEach((_REPORT) => {
              menu.children.push({
                title: _REPORT.label,
                target: "_self",
                href: `/reports-dynamic/${_REPORT.code}`,
                icon: "BatteryChargingIcon",
                roles: [_REPORT.value],
              });
            });
          }
        }

        menu.active = false;
        if (menu.main) {
          menu.children = [];
          menu.main.forEach((_main) => {
            if (_main.child) {
              // validar CHILD

              _main.child.forEach((_childen) => {
                _childen.active = _childen.roles === null;
                if (!_childen.active) {
                  if (_childen.roles && _childen.roles.length > 0) {
                    _childen.active = _childen.roles.some((srule) =>
                      userData.roles.some((s) => s === srule)
                    );
                  }
                }
              });
              // validar CHILD

              //
              _main.children = _main.child.filter((f) => f.active);
              _main.active = _main.children.some((f) => f.active);
              if (_main.active) {
                menu.children.push(_main);
                menu.active = true;
              }
            } else if (_main.roles) {
              _main.roles.forEach((roleMenu) => {
                if (!_main.active) {
                  _main.active = userData.roles.some((s) => s === roleMenu);
                  if (_main.active) {
                    menu.children.push(_main);
                    menu.active = true;
                  }
                }
              });
            }
          });
        } else if (menu.children) {
          menu.children.forEach((_childen) => {
            _childen.active = _childen.roles === null;
            if (!_childen.active) {
              if (_childen.roles && _childen.roles.length > 0) {
                _childen.active = _childen.roles.some((srule) =>
                  userData.roles.some((s) => s === srule)
                );
              }
            }
          });

          // apenas menus com permissão
          menu.children = menu.children.filter((f) => f.active);
          menu.active = menu.children.some((f) => f.active);
        } else if (menu.roles) {
          menu.roles.forEach((roleMenu) => {
            if (!menu.active) {
              menu.active = userData.roles.some((s) => s === roleMenu);
            }
          });
        }
      });
      return _rrotas;
    },
    execLogin() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    login() {
      const payload = {
        email: this.userEmail,
        password: this.password,
        token: this.$route.query.token,
        google_token: this.google_token,
      };
      this.loading = true;
      _authService
        .login(payload)
        .then((res) => {
          useJwt.setToken(res.content.accessToken);
          useJwt.setRefreshToken(res.content.accessToken);

          // rotas
          this.getrotas();

          // exibir menu
          const _navMenus = this.getMenus(res.content.data);

          // menus do usuário
          res.content.data.navMenus = _navMenus;

          // dados usuario
          localStorage.setItem("userData", JSON.stringify(res.content.data));
          this.$ability.update([{ action: "manage", subject: "all" }]);

          // redirect by query
          if (this.$route.query.redirect) {
            const redirect_url = this.$route.query.redirect.replace(/_/g, "/");
            this.$router.push({
              path: `/${redirect_url}`,
            });
            return;
          }

          // redirect
          if (this.$route.params.redirect) {
            const redirect_url = this.$route.params.redirect.replace(/_/g, "/");
            this.$router.push({
              path: `/${redirect_url}`,
            });
          } else {
            // const rulesView = res.content.data.roles.filter((f) =>
            //   f.includes("view")
            // );

            // var _rotas = this.getrotas();
            // var _route = "";

            // rulesView.forEach((_rule) => {
            //   if (_rotas.some((s) => s.name.includes(_rule))) {
            //     const rr = _rotas.filter((s) => s.name.includes(_rule))[0];
            //     if (!_route) {
            //       _route = rr.route;
            //     }
            //   }
            // });

            // if (!_route) {
            //   _route = "dashboard";
            // }

            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.ShowAlert(error);
        });
    },

    ShowAlert(msg) {
      this.$swal({
        title: "Erro ao Logar!",
        text: msg,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    setConfigAccounts() {
      this.$store.dispatch("verticalMenu/UpdateMenuCollapsed", false);
      localStorage.setItem("login-accounts", "true");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>